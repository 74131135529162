import { useEffect } from 'react'
import { navigate } from 'gatsby'

const IndexPage = () => {
  useEffect(() => {
    navigate('/de/home/')
  }, [])

  return null
}

export default IndexPage
